html,
body {
  overflow-x: hidden;
  height: 100vh;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#appbar-container {
  height: 64px;
}

#component-container {
  margin: 30px;
  min-height: 90%;
}

.coverflow-image {
  display: block;
  width: 100%;
  height: auto;
  image-rendering: optimizeQuality;
  image-rendering: -moz-crisp-edges; /* Firefox             */
  image-rendering: -o-crisp-edges; /* Opera               */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
  -ms-interpolation-mode: nearest-neighbor; /* IE8+                */
}

.full-screen-container {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 10fr 1fr;
}

.full-width {
  width: 100%;
}

.login-background {
  justify-content: center;
  padding: 50px;
}

.well {
  min-height: 20px;
  background-color: #e5e5e5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 10px;
}

.grey-border-container {
  border: solid grey;
  border-radius: 25px;
}

.preview-pic {
  width: 100%;
  height: auto;
}

.drag-and-drop {
  border: 2px dotted grey;
  background-color: #e5e5e5;
}

.editor-canvas {
  width: 100%;
  height: 100%;
}

li {
  margin: 15px 0;
}

/*Quill CSS*/

.ql-tooltip {
  width: 360px;
}

.ql-container {
  height: auto;
}

.ql-editable {
  border: 2px solid #24b1dd;
}

.ql-font {
  width: 170px !important;
}

.ql-size {
  width: 50px !important;
}

.ql-bubble .ql-picker.ql-font {
  font-size: 20px;
}

.ql-bubble .ql-picker {
  font-size: 20px;
}

.ql-bubble .ql-toolbar button {
  height: 30px;
}

.ql-editor.ql-blank::before {
  text-align: center;
}

#toolbar-container .ql-font span[data-label='Libre Baskerville']::before {
  font-family: 'Libre Baskerville';
}

#toolbar-container .ql-font span[data-label='Arial']::before {
  font-family: 'Arial';
}

#toolbar-container .ql-font span[data-label='Dancing Script']::before {
  font-family: 'Dancing Script';
}
/* Set content font-families */

.ql-font-arial {
  font-family: 'Arial', sans-serif;
}

.ql-font-dancingScript {
  font-family: 'Dancing Script', cursive;
}

.ql-font-libreBaskerville {
  font-family: 'Libre Baskerville', serif;
}

.ql-size-12px {
  font-size: 12px;
}

.ql-size-14px {
  font-size: 14px;
}

.ql-size-16px {
  font-size: 16px;
}

.ql-size-18px {
  font-size: 18px;
}

.ql-size-20px {
  font-size: 20px;
}

.ql-size-24px {
  font-size: 24px;
}

.ql-size-32px {
  font-size: 32px;
}

/* react-pdf CSS */
.react-pdf__Page {
  padding: 10px 0;
  background-color: #e5e5e5;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}
