body, html {
    height: 100%
  }
  
  .bgimg {
    background-image: url('https://www.w3schools.com/w3images/forestbridge.jpg');
    height: 100%;
    background-position: center;
    background-size: cover;
    position: relative;
    color: white;
    font-family: "Courier New", Courier, monospace;
    font-size: 25px;
  }
  
  .topleft {
    position: absolute;
    top: 0;
    left: 16px;
  }
  
  .bottomleft {
    position: absolute;
    bottom: 0;
    left: 16px;
  }
  
  .center {
    text-align: center;
  }
  
  hr {
    margin: auto;
    width: 40%;
  }

  .white-text {
    color: white;
    font-family: "Courier New", Courier, monospace;
  }